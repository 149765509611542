<template lang="pug">
.message
  div(:class='from')
    .bubble(:class='error_class')
      span.exclamation-sign.fas.fa-exclamation-circle(v-if="errored")
      span(v-html="this.processed_text")
      .with-attachments(v-if="this.message.has_attachments")
        span.fas.fa-paperclip
        span &nbsp;Message has attachments
    .clear
    .message-info
      .error.
        {{ message.send_error }}
      .sender-name(v-if="!errored && message.sender_name").
        Sent by {{ message.sender_name }}
      .time(:class='error_class')
        .span(v-if="!is_valid_time") {{ message.time }}
        timeago(:datetime="this.datetime" :auto-update="60" :title="formatted_time" v-if="is_valid_time")
      //- .retry(v-if="errored")
      //-   i.fas.fa-undo
      //-   a(href="#") Retry

</template>

<script>
import Autolinker from 'autolinker'

export default {
  props: ['message'],
  data() {
    return {}
  },
  computed: {
    from() {
      return this.message.outgoing ? 'outgoing' : 'incoming'
    },
    processed_text() {
      if (this.message.text == null) { return '' }

      const text = this.message.text.replaceAll('\n', '<br/>')
      return Autolinker.link(text, { stripPrefix: false })
    },
    datetime() {
      return new Date(this.message.time * 1000)
    },
    formatted_time() {
      return new Date(this.message.time * 1000).toLocaleString('en-US')
    },
    is_valid_time() {
      return Number.isInteger(this.message.time)
    },
    errored() {
      return this.message.send_error !== undefined && this.message.send_error !== null
    },
    error_class() {
      return this.errored ? 'error' : ''
    }
  },
  methods: {
  }
}
</script>

<style lang="sass" scoped>
@import './../_variables'
@import './../_mixins'

.messages-container
  .right
    .message
      margin-bottom: 12px

      .bubble
        overflow-wrap: break-word
        max-width: 100%
        font-size: 14px
        position: relative
        display: inline-block
        clear: both
        padding: 6px 20px 14px 14px
        vertical-align: top
        border-radius: 5px
        max-width: 350px

        .with-attachments
          margin-top: 7px
          font-style: italic

        .exclamation-sign
          color: $error-color
          margin-right: 5px

        a
          text-decoration: underline

        &:before
          position: absolute
          top: 19px
          display: block
          width: 8px
          height: 6px
          content: '\00a0'
          transform: rotate(29deg) skew(-35deg)

        &:after
          clear: both

      .clear
        clear: both

      .message-info
        color: gray
        font-size: 9px

        .error
          color: red

        .retry
          color: $blue

          i
            margin-right: 2px

          a
            color: $blue

      .incoming
        .bubble
          float: left
          color: $dark
          background-color: #eceff1
          align-self: flex-start

          &:before
            left: -3px
            background-color: #eceff1

        .message-info
          float: left
          text-align: left
          margin-left: 3px

      .outgoing
        .bubble
          float: right
          color: $white
          background-color: $blue
          align-self: flex-end
          background-color: $blue

          &:before
            right: -3px
            background-color: $blue

          a, a:hover
            color: white

          &.error
            background-color: $error-background
            color: #555

            &:before
              right: -3px
              background-color: $error-background

        .message-info
          float: right
          text-align: right
          margin-right: 3px

// Overrides for small screens
@media only screen and (max-width: 768px)
  .messages-container
    .right
      .message .bubble
          max-width: auto
</style>
