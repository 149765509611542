<template lang="pug">
.panel.add-lead-panel(:class="panel_class")
  .add-lead-form(v-if='have_required_ids && !added')
    span.description.
      Send to
      <strong>{{ details['dealership'] }}</strong>
      a lead insterested in
      <strong>{{ details['name'] }}</strong>

    FormKit(type="form" @submit="addLeadSubmitted" v-model="form_data" :errors="form_errors" :input-errors="input_errors" submit-label="Add Lead")
      FormKit(type="text" name="first_name" label="First name" validation="required" validation-name="First name")
      FormKit(type="text" name="last_name" label="Last name" validation="required" validation-name="Last name")
      FormKit(type="email" name="from" label="Email" validation-name="Email")
      FormKit(type="text" name="phone" label="Phone" validation="")

    //- FormulateForm(@submit="addLeadSubmitted" :errors="input_errors" :form-errors="form_errors" :key='form_key' v-model="form_data")
    //-   FormulateInput(name="first_name" label="First name" validation="required" validation-name="First name" :disabled="!ready")
    //-   FormulateInput(name="last_name" label="Last name" validation="required" validation-name="Last name" :disabled="!ready")
    //-   FormulateInput(name="from" label="Email" validation="optional|email" validation-name="Email" :disabled="!ready")

    //-   FormulateInput(name="phone" label="Phone" validation="" :disabled="!ready")

    //-   FormulateErrors
    //-   FormulateInput(type="submit" :label="ready ? 'Add Lead' : 'Sending...'" :disabled="!ready")

  .span.successfully-added(v-if='added')
    .far.fa-check-circle
    .added-text Added!
    .added-link
      a(href="#" @click.stop.prevent="reset") Add more

  .panel-warning(v-if='!have_required_ids').
    Can't add a lead without vehicle info.
</template>

<script>
import axios from 'axios'
import { nanoid } from 'nanoid'
import { get } from 'lodash'

export default {
  props: ['panel_class', 'details'],
  data() {
    return {
      ready: true,
      added: false,
      form_data: {},
      input_errors: {},
      form_errors: []
    }
  },
  computed: {
    have_required_ids() {
      if (!this.details) { return false }

      return this.details.inventory_id && this.details.dealership_id !== null
    },
    csrf_token() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  methods: {
    reset() {
      this.added = false
      this.input_errors = {}
      this.form_errors = []
    },
    checkPhoneOrEmailPresence() {
      return Boolean(this.form_data.phone) || Boolean(this.form_data.from)
    },
    async addLeadSubmitted(data) {
      if (!this.checkPhoneOrEmailPresence()) {
        this.form_errors = ['The lead should have either an email or a phone.']
        return false
      }

      this.added = false
      this.ready = false
      this.input_errors = {}
      this.form_errors = []

      await axios
              .post(window.location.href + '/leads/',
                {
                  lead: data,
                  authenticity_token: this.csrf_token,
                  account_id: this.details.dealership_id,
                  inventory_id: this.details.inventory_id
                })
              .then((response) => {
                this.added = true
                this.form_data.first_name = null
                this.form_data.last_name = null
                this.form_data.phone = null
                this.form_data.from = null
              })
              .catch((error) => {
                const data = error.response.data
                const errors = data.errors

                this.input_errors = errors

                if (get(errors, 'base') !== undefined) {
                  this.form_errors = errors.base
                } else if (get(data, 'message') !== undefined) {
                  this.form_errors.push(data.message)
                }
              })
              .then((response) => {
                this.ready = true
              })
    }
  },
  watch: {
  }
}
</script>

<style lang="sass" scoped>
  .description
    font-size: 14px

  .successfully-added
    text-align: center
    font-size: 34px
    font-weight: bold
    color: green

    .added-link
      font-size: 18px
</style>
