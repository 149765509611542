<template lang="pug">
#vm-messaging.messages-wrapper
  //- .messages-settings
  //-   a(data-toggle="collapse" href="#pagesList" role="button") Select pages

  //-   #pagesList.collapse
  //-     .card.card-body
  //-       div(v-for="page in pages" class="form-check" :key="page.id")
  //-         input(type="checkbox" class="form-check-input" :id='"page-" + page.id' :name='"page-" + page.id' v-model="selected_pages")
  //-         label(class="form-check-label" :for='"page-" + page.id') {{ page.page_name }}

  //-       button.btn.btn-small.btn-success Apply

  .messages-container
    .left#left-pane(:class='left_tab_class')
      .loading(v-if="state == 'loading'")
        .d-flex.justify-content-center
          .spinner-border(role="status")
            .sr-only Loading...
      ul.people(v-if="state != 'loading'")
        convo(v-for="convo in conversations" :convo='convo' :key="convo.id" :active_convo='active_convo_id' @update='convoUpdate')

    .right(:class='right_tab_class')
      .top
        span.to('v-if'='active_convo_id')
          span.fas.fa-chevron-left.back-btn(@click='toConvosClicked')
          span.name {{ active_name }}
          span.links
            a.mark-unread.fas.fa-undo(title="Mark as unread" @click.stop.prevent='markActiveConversationAsUnread')
            a.fas.fa-archive(title="Archive" @click.stop.prevent='archiveActiveConversation')

      #chatpane.chat(ref='chatpane')
        //- For further use
        //- .conversation-start
          //- span XXXXX, X:XX XX

        message(v-for='message in messages' :message='message' :key='message.id' v-if='active_convo_id')

        .select-chat(v-if='!active_convo_id').
          Please select a chat to start messaging.

      .write-wrapper
        writepane(:active_convo='active_convo_id' ref='writepane' @send_message='sendMessageClicked')

    .panels
      .top
        select.form-select.form-select-sm.panel-select(@change="panelSelectChanged")
          option(value="info" selected) Info
          option(value="add-lead") Add Lead

      infoPanel(:panel_class="activePanelClass('info')" :details="details")
      addLeadPanel(:panel_class="activePanelClass('add-lead')" :details="details" ref='add_lead_panel')
</template>

<script>
import arrayFunctions from '~/array_functions'

import axios from 'axios'

import writepane from './components/writepane.vue'
import message from './components/message.vue'
import convo from './components/conversation.vue'
import addLeadPanel from './components/add_lead_panel.vue'
import infoPanel from './components/info_panel.vue'

export default {
  components: {
    writepane, convo, message, addLeadPanel, infoPanel
  },
  name: 'Messenger',
  data() {
    return {
      state: '',
      conversations: [],
      details: null,
      pages: [],
      selected_pages: [],
      messages: [],
      active_convo_id: 0,
      active_name: '',
      left_tab_class: 'active',
      right_tab_class: '',
      active_panel_name: 'info'
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    this.conversations = conversations
    // this.fetchDialogs()
    // this.fetchPages()
    this.activeConvoChanged()
  },
  watch: {
    active_convo_id: 'activeConvoChanged'
  },
  computed: {
    csrf_token() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  methods: {
    panelSelectChanged(e) {
      this.active_panel_name = e.target.value
    },
    activePanelClass(name) {
      return (name === this.active_panel_name) ? 'active' : ''
    },
    becomeReady() {
      this.state = 'ready'
    },
    becomeLoading() {
      this.state = 'loading'
    },
    scrollToConvo(id) {
      const list = document.getElementById('left-pane')
      const targetLi = document.getElementById('convo-' + id)

      list.scrollTop = (targetLi.offsetTop - 218)
    },
    fetchConvos() {
      this.becomeLoading()
      this.conversations = []

      axios
        .get(window.location.href + '/conversations')
        .then(response => {
          this.conversations = response.data
          this.becomeReady()
        })
    },
    fetchPages() {
      axios
        .get(window.location.href + '/pages')
        .then(response => {
          this.pages = response.data
        })
    },
    // setInitialDialog () {
    //   if (initial) {
    //     this.active_convo_id = initial

    //     this.$nextTick(function() {
    //       this.scrollToDialog(initial)
    //     })

    //     if (history.replaceState) {
    //       history.replaceState('', 'Messages', '/');
    //     }
    //   }
    // },
    activeConvoChanged() {
      const ad = this.activeConversation()

      if (ad) {
        this.toChatTab()

        this.changeConvoReadState(ad.id, true)
        this.messages = ad.messages
        this.details = ad.details
        this.active_name = ad.name

        this.resortMessages()
        this.scrollChatToBottom()
        this.$refs.add_lead_panel.reset()
      }
    },

    convoUpdate(id) {
      this.active_convo_id = id
    },
    findConvo(id) {
      return this.conversations.filter(function (d) { return d.id === id })[0]
    },
    findConvoIndex(id) {
      return this.conversations.map(function (d) { return d.id }).indexOf(id)
    },
    findMessageIndex(id) {
      return this.messages.map(function (m) { return m.id }).indexOf(id)
    },
    findMessageIndexInConvo(id, d) {
      return d.messages.map(function (m) { return m.id }).indexOf(id)
    },
    activeConversation() {
      return this.findConvo(this.active_convo_id)
    },
    removeConversation(id) {
      const index = this.findConvoIndex(id)

      if (index !== -1) {
        this.conversations.splice(index, 1)
      }

      if (this.active_convo_id === id) {
        this.resetConvo()
      }
    },
    archiveActiveConversation() {
      if (!confirm('This will archive the conversation. Are you sure?')) { return null }

      this.changeConvoArchivedState(this.active_convo_id, true)
      this.removeConversation(this.active_convo_id)
    },
    changeConvoArchivedState(id, archived) {
      if (id === 0) { return null }

      const convo = this.findConvo(id)

      if (archived !== convo.archived) {
        axios.patch(window.location.href + `/conversations/${id}/archived_state`,
          { archived_state: archived, authenticity_token: this.csrf_token })
      }
    },
    changeConvoReadAppearance(id, read) {
      const d = this.findConvo(id)

      if (d) {
        d.read = read
      }
    },
    updateConvoDetails(id, details) {
      const convo = this.findConvo(id)
      convo.details = details

      if (this.active_convo_id === id) {
        this.details = details
      }
    },
    markActiveConversationAsUnread() {
      this.changeConvoReadState(this.active_convo_id, false)
    },
    changeConvoReadState(id, read) {
      if (id === 0) { return null }

      const convo = this.findConvo(id)

      if (read !== convo.read) {
        this.changeConvoReadAppearance(id, read)
        axios.patch(window.location.href + `/conversations/${id}/read_state`,
          { read_state: read, authenticity_token: this.csrf_token })
      }
    },
    toChatTab() {
      this.right_tab_class = 'active'
      this.left_tab_class = ''
    },
    toConvosClicked() {
      this.resetConvo()
      this.right_tab_class = ''
      this.left_tab_class = 'active'
    },
    resetConvo() {
      this.active_convo_id = 0
      this.messages = []
      this.details = null
      this.active_name = ''
      this.$refs.add_lead_panel.reset()
    },
    scrollChatToBottom() {
      this.$nextTick(function () {
        let chatpane = this.$refs.chatpane
        chatpane.scrollTop = chatpane.scrollHeight
      })
    },
    sendMessageClicked(text, imprint) {
      this.messages.push({ text: text, outgoing: true, imprint: imprint, time: 'sending...' })
      this.scrollChatToBottom()
      this.moveConvoToTop(this.activeConversation())

      axios.post(window.location.href + `/conversations/${this.active_convo_id}/messages`,
                 { message: { text: text, imprint: imprint } , authenticity_token: this.csrf_token })

    },
    moveConvoToTop(convo) {
      const index = this.conversations.indexOf(convo)
      if (index !== -1) {
        this.conversations = arrayFunctions.moveArrayItem(this.conversations, index, 0)
      }
    },
    resortMessages() {
      this.messages = this.messages.sort(function (a, b) { return a.time - b.time })
    },
    messageReceived(data) {
      // Find correct convo obj
      let rd = this.findConvo(data.conversation.id)

      if (!rd) { // If not exist
        const d = data.conversation
        this.conversations.unshift(d)

        d.messages = []

        rd = this.findConvo(data.conversation.id)
      }

      let m = null

      // Find if a "sending..." message exists by imprint
      if (data.message.imprint) {
        m = rd.messages.filter(function (m) { return m.imprint === data.message.imprint })[0]
      }

      // If yes, update it
      if (m) {
        const index = rd.messages.indexOf(m)
        rd.messages[index] = data.message
      } else {
        // Push message
        rd.messages.push(data.message)
      }

      this.resortMessages()

      rd.read = data.conversation.read

      // If message arrived to active convo
      if (rd.id === this.active_convo_id) {
        this.scrollChatToBottom()

        // Mark active convo as read, if message is incoming
        if (data.message.outgoing === false) {
          this.changeConvoReadState(this.active_convo_id, true)
        }
      }

      // Move last convo to the top
      this.moveConvoToTop(rd)
    }
  }
}
</script>

<style lang="sass" src="./comm.sass"></style>
