import { createApp } from 'vue'

import TurbolinksAdapter from 'vue-turbolinks'
import VueTimeago from 'vue-timeago3'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'

import Comm from './comm.vue'

document.addEventListener('turbo:load', () => {
  if (document.getElementsByClassName('vm-comm').length) {
    const app = createApp(Comm)
    app.use(TurbolinksAdapter)
    app.use(VueTimeago, {
      name: 'Timeago'
    })

    app.use(plugin, defaultConfig)

    window.vmComm = app.mount('.vm-comm')
  }
})
