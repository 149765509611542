<template lang="pug">
li.person(:class="[active_class, unread_class]" @click='activate(active_convo)' v-bind:id='"convo-" + convo.id')
  img(:src='convo.picture_url' v-if='convo.picture_url')
  span.no-image(v-if='!convo.picture_url') {{ convo.user_name && convo.user_name[0].toUpperCase() }}
  span.name {{ convo.user_name }}
  span.time
    .span(v-if="!is_valid_time") {{ last_time }}
    timeago(:datetime="last_datetime" :auto-update="60" :title="formatted_last_time" v-if="is_valid_time")
  span.page {{ convo.page_name }}
  span.preview {{ last_text }}
  span.unread-flag(v-if="!convo.read")
</template>

<script>
export default {
  props: ['convo', 'active_convo'],
  data() {
    return {
    }
  },
  computed: {
    last_time() {
      const msgs = this.convo.messages
      return msgs && msgs.length ? msgs[msgs.length - 1].time : null
    },
    last_text() {
      const msgs = this.convo.messages

      if (!(msgs && msgs.length)) { return null }

      return msgs[msgs.length - 1].text
    },
    active_class() {
      return this.convo.id === this.active_convo ? 'active' : ''
    },
    unread_class() {
      return this.convo.read === false ? 'unread' : ''
    },
    last_datetime() {
      return new Date(this.last_time * 1000)
    },
    formatted_last_time() {
      return new Date(this.last_time * 1000).toLocaleString('en-US')
    },
    is_valid_time() {
      return Number.isInteger(this.last_time)
    }
  },
  methods: {
    activate(p) {
      this.$emit('update', this.convo.id)
    }
  },
  watch: {
  }
}
</script>

<style lang="sass" scoped>
</style>
