<template lang="pug">
.write(v-if='active_convo')
  form#message_form(action="#" ref="message_form" v-on:submit.stop.prevent='messageFormSubmit')
    textarea#message_text_input(type="text" ref="message_text_input" @keypress.stop="inputKeypressed" autocomplete="off")

    span#send_button.fas.fa-paper-plane.write-link.send(v-on:click='messageFormSubmit')
</template>

<script>
export default {
  props: ['active_convo', 'new_contact_id', 'new_phone_number'],
  template: '#writepane-template',
  methods: {
    random_id() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
    },
    messageFormSubmit() {
      if (this.$refs.message_text_input.value === '') {
        return false
      } else {
        const text = this.$refs.message_text_input.value
        const imprint = this.random_id()
        this.$refs.message_text_input.value = ''

        this.$emit('send_message', text, imprint)
      }
    },
    inputKeypressed(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        this.messageFormSubmit()
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
