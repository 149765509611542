<template lang="pug">
.panel.info-panel(:class="panel_class")
  .info(v-if='details')
    .info-section
      .info-section-name 🚘 {{ details['name'] }}
      .info-field 💵 {{ details['price'] }}
      .info-field 🏁 {{ details['mileage'] }} miles
      .info-field
        a(:href="details['url']" target="_blank") More details

    .info-section
      .info-section-name {{ details['dealership'] }}
      .info-field 📞 {{ details['phone'] }}
      .info-field 🏢 {{ details['address'] }}

  .panel-warning(v-if='!details').
    No information.
</template>

<script>
export default {
  props: ['panel_class', 'details'],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="sass" scoped>
  .info-panel
    .info
      .info-section
        margin: 0 0 10px

        .info-section-name
          font-weight: bold
</style>
