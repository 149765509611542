/* eslint-disable no-undef */

import consumer from './consumer'

consumer.subscriptions.create('ConversationChannel', {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (vmComm) {
      switch (data.action) {
        case 'new':
          vmComm.messageReceived(data)
          break
        case 'read_state':
          vmComm.changeConvoReadAppearance(data.id, data.read_state)
          break
        case 'archived_state':
          if (data.archived_state === true) {
            vmComm.removeConversation(data.id)
          }

          break
        case 'details_update':
          vmComm.updateConvoDetails(data.id, data.details)
          break
      }
    }
  }
})
